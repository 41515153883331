<template>
  <div class="mediaBox">
    <div class="mediaCenter">
      <div class="top">
        <img v-if="!xsintlg" src="../../../static/index/mediaTop.png" alt="">
        <img v-if="xsintlg" src="../../../static/index/mediaTop_xsintlg.png" alt="">
      </div>
      <div class="bottom" v-if="control">
        <img v-if="!xsintlg" src="../../../static/index/mediaBottom.png" alt="">
        <img v-if="xsintlg" src="../../../static/index/mediaBottom_xsintlg.png" alt="">
      </div>
      <p v-if="!control" @click="controlShow">
        <span>展开</span>
        <img src="../../../static/index/media2.png" alt="">
      </p>
      <p v-if="control" @click="controlShow">
        <span>收起</span>
        <img src="../../../static/index/media1.png" alt="">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name:'media',
  data() {
    return {
      // 控制是否展开
      control: false,
      xsintlg: false,
    }
  },
  created(){
    if(window.location.origin.indexOf('xsintlg')!==-1){
      console.log('我是xsintlg');
      this.xsintlg = true;
    }
  },
  methods:{
    controlShow(){
      this.control = !this.control;
    },
  }
}
</script>

<style lang="less" scoped>
  .mediaBox{
    max-width: 1416px;
    margin: 0 auto;
    .mediaCenter{
      max-width: 1275px;
      margin: 0 auto;
      .top{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .bottom{
        width: 100%;
        margin: 0 auto;
        margin-right: -10px;
        img{
          width: 100%;
        }
      }
      p{
        width: 160px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin: 95px auto;
        cursor: pointer;
        span{
          color: #9a9a9a;
          margin-right: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .media{
      width: 100%;
      .mediaBox{
        width: 100%;
        .mediaCenter{
          width: 100%;
          .top{
            background-size: contain;
            height: 157px;
          }
          p{
            margin: 30px auto;
            width: 120px;
            height: 35px;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>