<template>
  <div class="indexBox">

    <div v-if="!controlSwiper" class="bigImg">
      <img v-if="controlBigImg" @click="jumpClick(justOneImg[0].Link)"
        :src="justOneImg[0].Url !== '' ? justOneImg[0].Url : ''" alt="" />
    </div>
    <div class="swiperBox" v-if="controlSwiper">
      <swiper ref="mySwiper" :options="swiperOption">
        <div class="swiper-slide" v-for="(item, i) in swiperData" :key="i" @click="jumpClick(item.url)">
          <img :src="item.imgUrl" />
        </div>
        <!-- <div class="swiper-slide">
          <img src="../../static/aboutus/banner.jpg" />
        </div>
        <div class="swiper-slide">
          <img src="../../static/index/banner.png" />
        </div> -->
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <gif-vue></gif-vue>
    <tit-vue :titleContent="changeTitle.titleTxt1"></tit-vue>
    <roll-vue></roll-vue>
    <am-download-vue></am-download-vue>
    <tit-vue :titleContent="changeTitle.titleTxt2"></tit-vue>
    <data-center-vue></data-center-vue>
    <div class="information">
      <tit-vue :titleContent="changeTitle.titleTxt3"></tit-vue>
      <information-vue></information-vue>
    </div>
    <div class="media">
      <tit-vue :titleContent="changeTitle.titleTxt4"></tit-vue>
      <media-vue></media-vue>
    </div>
    <div class="webSocket" :class="websocketScrollTop ? 'fixedWebSocket' : ''">
      <div class="wssBox">
        <div>
          <ul>
            <li class="liFirst">
              <span>伦敦金</span>
              <p>
                <span>{{ London.goldRise }}</span>
                <span>{{ London.goldPer }}</span>
              </p>
            </li>
            <li class="liSecond">
              <p>
                <span :class="London.controlPic ? 'classRed' : 'classGreen'">{{ London.gold }}</span>
                <img :src="London.controlPic ? LondonImg[0] : LondonImg[1]" alt="" />
              </p>
            </li>
          </ul>
          <ul>
            <li class="liFirst">
              <span>伦敦银</span>
              <p>
                <span>{{ London.silverRise }}</span>
                <span>{{ London.silverPer }}</span>
              </p>
            </li>
            <li class="liSecond">
              <p>
                <span :class="London.controlPicOther ? 'classRed' : 'classGreen'">{{ London.silver }}</span>
                <img :src="London.controlPicOther ? LondonImg[0] : LondonImg[1]" alt="" />
              </p>
            </li>
          </ul>
        </div>
        <img @click="appDownload" src="../../static/index/websocketDownload.png" alt="" />
      </div>
    </div>
    <div class="Float" v-if="controlFloat" @click="jumpActivity">
      <img @click.stop="() => {
        controlFloat = false;
      }
      " src="../../static/index/closer.png" alt="" />
      <div class="floatBox" v-if="openFloat">
        <ul class="codeBox">
          <li>
            <!-- <img src="../../static/index/iosCode.png" alt=""> -->
            <Qrcode :link="iosMt5DownloadUrl" :size="110" :iconSize="30"/>
            <span>IOS</span>
          </li>
          <li>
            <!-- <img :src="SPuRl" :key="SPuRl" alt=""> -->
            <Qrcode :link="androidDownloadUrl" :size="100" />
            <span>Android</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 新年装饰2 -->
    <!-- <div class="dark" v-if="controlDark">
       <canvas id="canvasParticle" style="position:relative;z-index:3;" width="1920" height="1000">Canvas is not supported in your brower.</canvas>
      <div class="background"></div>
      <img src="../../static/index/door.png"  alt="">
      <img class="closer3" @click="()=>{controlDark = false}" src="../../static/index/closer3.png" alt="">
    </div>
    <div class="containers">
    
  </div> -->
    <!-- <img class="lanternLeft" src="../../static/index/lanternLeft.png" alt="">
    <img class="lanternRight" src="../../static/index/lanternRight.png" alt=""> -->
    <div class="liveRoom" v-show="isTrueShowRoom">
      <live-room @nowLiving="showRoom"></live-room>
    </div>
  </div>

</template>
<script>
import GifVue from './index/gif.vue';
import RollVue from './index/roll.vue';
import TitVue from './index/tit.vue';
import AmDownloadVue from './index/amDownload.vue';
import DataCenterVue from './index/dataCenter.vue';
import InformationVue from './index/information.vue';
import MediaVue from './index/media.vue';
import liveRoom from './index/liveRoom.vue';
import { analyticalData } from '../../utils/tools';
import eventBusMitter from '../../utils/eventbus';
import { swiperBanner, maidianOpenreal } from '../../api/info';
import Qrcode from './QrCode/index.vue'
import { mapState  } from "vuex";
export default {
  name: 'Index',
  components: {
    GifVue,
    RollVue,
    TitVue,
    AmDownloadVue,
    DataCenterVue,
    InformationVue,
    MediaVue,
    liveRoom,
    Qrcode,
  },
  metaInfo: {  
      title: '红狮金业官网,黄金交易平台,红狮启富,持牌正规安全首选红狮金业',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
  data() {
    return {
      openFloat: false,
      pic: [require('../../static/index/zs-1.png'), require('../../static/index/zs-2.png'), require('../../static/index/zs-3.png'), require('../../static/index/zs-4.png'), require('../../static/index/zs-5.png')],
      showPopup: false,
      LondonImg: [require('../../static/index/jiaohu/up.png'), require('../../static/index/jiaohu/down.png')],
      controlSwiper: false,
      controlBigImg: false,
      SPuRl: require('../../static/index/androidcode.png'),
      // swiper 参数
      swiperOption: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
          // type:'custom',
          // renderCustom: function (swiper, current, total) {
          //   if(current === 1){
          //     return '<span class="swiperOne"><li><span></span></li><span class="swiperOneSpan"></span><span></span></span>';
          //   }else if(current === 2){
          //     return `<span class="swiperTwo"><span></span><li><span></span></li><span></span></span>`;
          //   }else{
          //     return `<span class="swiperThree"><span></span><span class="swiperThreeSpan"></span><li><span></span></li></span>`;
          //   }
          // },
        },
      },
      // 控制websocket模块是否定位
      websocketScrollTop: false,
      London: {
        gold: '--',
        silver: '--',
        goldRise: '0.0',
        silverRise: '0.0',
        goldPer: '0.00%',
        silverPer: '0.00%',
        controlPic: true,
        controlPicOther: true,
      },
      changeTitle: {
        titleTxt1: ['专注伦敦金交易，一站式管家服务', false, 1, { img: 1 }],
        titleTxt2: ['数据中心', true, 1],
        titleTxt3: ['行情资讯', true, 2],
        titleTxt4: ['合作媒体', false],
      },
      // 获取到的swiper链接地址
      swiperData: {},
      // 给bigImg动态添加class名
      justOneImg: [
        {
          Link: '',
          Url: '',
        },
      ],
      goldCl: '',
      silverCl: '',
      screenWidth: document.body.clientWidth,
      controlFloat: true,
      controlDark: true,
      isTrueShowRoom: false, //控制liveRoom的显示与隐藏
    };
  },
  watch: {

  },
  created() {
    //  const Rhref = window.location.host;
    // if(Rhref.indexOf('rlchltd')>-1){
    //     this.SPuRl = require('../../static/index/androidcode2.png')
    // }else{
    //    this.SPuRl = require('../../static/index/androidcode.png')
    // }
    // 自动切换serveBox
    setInterval(this.updateDiv, 4000);
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
    // 一旦进入首页，删除session中的key，目的让tabbar不显示
    sessionStorage.removeItem('key');
    // 初始化swiperBanner
    this.InitSwiperBanner({
      // PlatformType: 11,
      // BannerType: 20,
      // BannerType: 1,
      languageidentification: 1,
       type: 20,
        region: 1
    });
    // crm数据埋点
    // maidian({ eventname: 'z1', eventdescribe: '访客数据', eventtype: 'visit' },undefined,{toPath:'www.123.com',fromPath:'www.baidu.com'},'indexMaidian');
    maidianOpenreal({ eventname: 'z1', eventdescribe: '访客数据', eventType: 'visit' });
  },
  mounted() {
    this.checkMobile()
    console.log(window.location.host)
    //this.partical()
    // 在组件加载完成后，开始浮动动画
    // 初始化websocket
    const Rhref = window.location.host;
    if (Rhref.indexOf('rlchltd') > -1) {
      this.SPuRl = require('../../static/index/androidcode2.png')
    } else {
      this.SPuRl = require('../../static/index/androidcode.png')
    }
    console.log(this.SPuRl)
    this.initWebSocket('wss://' + Rhref + '/quote');
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.controlDark = true;
    }, 1000); // 延迟 1 秒显示弹窗

    // 在一定时间后关闭弹窗
    setTimeout(() => {
      this.controlDark = false;
    }, 5000); // 4 秒后关闭弹窗
  },
  methods: {
    partical() {
      var canvas = document.getElementById('canvasParticle')
      const W = window.innerWidth
      const H = 1000 // window.innerHeight
      var ctx = null
      canvas.setAttribute('width', W)
      canvas.setAttribute('height', H)
      ctx = canvas.getContext('2d')
      const config = {
        number: 30, // 生成的粒子数量
        snowArr: [],
        pic: this.pic
      }
      for (let i = 0; i < config.number; i++) {
        config.snowArr.push({
          x: Math.random() * W, // 定义每片粒子的X轴
          y: Math.random() * H, // Y轴
          toX: Math.random() * 1 + 3, // 粒子每一次渲染的X距离
          toY: Math.random() * 1 + 3,
          size: Math.random() * 100 + 5 // 粒子的大小
        })
      }
      const dropAnimation = () => {
        ctx.clearRect(0, 0, W, H) // 清除画布重新渲染
        let j = 0
        for (let i = 0; i < config.snowArr.length; i++) {
          const snow = config.snowArr[i]
          const snowImg = new Image()
          if (j > 24) {
            j = 0
          }
          snowImg.src = config.pic[j]
          ctx.drawImage(snowImg, snow.x, snow.y, snow.size, snow.size)
          snow.x = snow.x > W ? 0 : snow.x + snow.toX // 每调一次函数向右移动一点
          snow.y = snow.y > H ? 0 : snow.y + snow.toY // 向下移动
          j++
        }
        requestAnimationFrame(dropAnimation)
      }
      requestAnimationFrame(dropAnimation)
    },


    handleScroll() {
      //改变元素#searchBar的top值
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.screenWidth > 500) {
        if (scrollTop > 5600) {
          this.websocketScrollTop = true;
        } else {
          this.websocketScrollTop = false;
        }
      } else {
        if (scrollTop > 4300) {
          this.websocketScrollTop = true;
        } else {
          this.websocketScrollTop = false;
        }
      }
    },
    // websocket 方法
    initWebSocket(src) {
      // const wsuri = "wss://tradeapi-cy1.mcrubly.com:4433/quote";
      const wsuri = src;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      let actions = { t: 7, d: { k: 0, nq: true } };
      this.websocketsend(JSON.stringify(actions));
      let actions2 = { t: 21, d: ['GOLD', 'SILVER'] };
      this.websocketsend(JSON.stringify(actions2));
    },
    websocketonerror() {
      let _this = this;
      console.log('连接建立失败');
      const Rhref = window.location.host;
      this.initWebSocket('wss://' + Rhref + '/quote');
    },
    websocketonmessage(e) {
      const data = JSON.parse(e.data);
      if (data.t === 0) {
        for (const iterator of data.d) {
          const blob = window.atob(iterator.q);
          const symbol = analyticalData(data.d[0].q || '', 5);
          // console.log(symbol);
          if (symbol.SymbolName.slice(0, 4) == 'GOLD') {
            // 伦敦金
            this.London.gold = symbol.Bid.toFixed(3);
            let gold = symbol.Bid;
            if (Number(gold) > Number(this.London.gold)) {
              this.London.controlPic = true;
            } else {
              this.London.controlPic = false;
            }
            // if(this.London.gold == '--'){
            //   this.London.gold = symbol.Bid.toFixed(3);
            // }else{
            //   let gold = symbol.Bid;
            //   // 计算涨幅;
            //   this.London.goldRise =  Number(gold) - Number(this.London.gold);
            //   this.London.goldRise = this.London.goldRise.toFixed(3);
            //   // 计算百分比（涨幅/开盘价）
            //   if(sessionStorage.getItem('openGold')){
            //     this.London.goldPer = (this.London.goldRise / sessionStorage.getItem('openGold'))*100;
            //     this.London.goldPer = this.London.goldPer.toFixed(2) + '%';
            //   }
            //   if(Number(gold) > Number(this.London.gold)){
            //     this.London.controlPic = true;
            //   }else{
            //     this.London.controlPic = false;
            //   }
            //   this.London.gold = gold.toFixed(3);
            // }
          } else if (symbol.SymbolName.slice(0, 6) == 'SILVER') {
            // 伦敦银
            this.London.silver = symbol.Bid.toFixed(3);
            let silver = symbol.Bid;
            if (Number(silver) > Number(this.London.silver)) {
              this.London.controlPicOther = true;
            } else {
              this.London.controlPicOther = false;
            }
            // if(this.London.silver == '--'){
            //   this.London.silver = symbol.Bid.toFixed(3);
            // }else{
            //   let silver = symbol.Bid;
            //   // 计算涨幅;
            //   this.London.silverRise =  Number(silver) - Number(this.London.silver);
            //   this.London.silverRise = this.London.silverRise.toFixed(3);
            //   // 计算百分比（涨幅/开盘价）
            //   if(sessionStorage.getItem('openSilver')){
            //     this.London.silverPer = (this.London.silverRise / sessionStorage.getItem('openSilver'))*100;
            //     this.London.silverPer = this.London.silverPer.toFixed(2) + '%';
            //   }
            //   if(Number(silver) > Number(this.London.silver)){
            //     this.London.controlPicOther = true;
            //   }else{
            //     this.London.controlPicOther = false;
            //   }
            //   this.London.silver = silver.toFixed(3);
            // }
          }
        }
      } else if (data.t === 5) {
        // console.log('t=5');
        let open = data.d.filter((item) => {
          if (/(GOLD|SILVER)/.test(item.n)) {
            return item.n;
          }
        });
        open.forEach((element) => {
          if (element.n == 'GOLD') {
            // 伦敦金
            this.goldCl = element.cl;
          } else if (element.n == 'SILVER') {
            // 伦敦银
            this.silverCl = element.cl;
          }
        });
      }
      // 伦敦金 计算涨幅;
      this.London.goldRise = (Number(this.London.gold) - Number(this.goldCl)).toFixed(2);
      // 计算百分比（涨幅/昨收）
      this.London.goldPer = ((this.London.goldRise / this.goldCl) * 100).toFixed(2) + '%';
      // 伦敦银 涨幅
      this.London.silverRise = (Number(this.London.silver) - Number(this.silverCl)).toFixed(3);
      // 计算百分比（涨幅/昨收）
      this.London.silverPer = ((this.London.silverRise / this.silverCl) * 100).toFixed(2) + '%';
    },
    websocketsend(Data) {
      this.websock.send(Data);
    },
    websocketclose(e) {
      console.log('断开连接', e);
    },
    checkMobile() {
      const userAgent = window.navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.controlFloat = !mobileRegex.test(userAgent);
      console.log(this.controlFloat)
    },
    // app下载
    appDownload() {
      this.$router.push({
        name: 'Appdownload',
      });
      eventBusMitter.emit('appDownload', { key: '3' });
    },
    // 获取swiperBanner
    InitSwiperBanner(attr) {
      swiperBanner(attr)
        .then((res) => {
          if (res.Data.length !== 0) {
            if (res.Data.length > 1) {
              this.controlSwiper = true;
              this.swiperData = res.Data;
            } else {
              this.controlBigImg = true;
              this.justOneImg = res.Data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // swiper跳转链接
    jumpClick(attr) {
      window.open(attr);
    },
    jumpActivity() {
      // this.$router.push({
      //   name: 'newYear',
      // });
      this.openFloat = true;
    },
    // liveRoom传来的值
    showRoom(attr) {
      this.isTrueShowRoom = attr;
    },
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.scrollTop;
      console.log(screenHeight);
      if (screenHeight > 415) {
        return '550px';
      } else {
        return '250px';
      }
    },
    ...mapState('common', {
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
};
</script>

<style lang="less" scoped>
.indexBox {
  width: 100%;
  position: relative;

  .bigImg {
    width: 100%;
    height: 600px;
    background: url('../../static/index/banner.png') no-repeat;
    background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiperBox {
    width: 100%;
    max-height: 682px;

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          padding: 0 50px;
          box-sizing: border-box;

          img {
            width: 100%;
            max-height: 682px;
            object-fit: cover;
          }
        }
      }

      /deep/.swiper-pagination {
        .swiper-pagination-bullet-active {
          background: #d52226 !important;
        }

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .information {
    width: 100%;
    height: 1108px;
    background: url('../../static/index/informationBanner.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 60px 0 130px;
    margin-top: 150px;
  }

  .media {
    box-sizing: border-box;
    padding-top: 85px;
  }

  .webSocket {
    width: 100%;
    height: 135px;
    background: url('../../static/index/websocketBanner.png') no-repeat;
    background-size: cover;
    position: fixed;
    bottom: 0;

    .wssBox {
      max-width: 1088px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        width: 290px;
      }

      div {
        display: flex;
        justify-content: space-around;
        align-items: center;

        ul {
          width: 280px;
          height: 70px;
          background: url('../../static/index/websocketBtn.png') no-repeat;
          background-size: cover;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          margin: 0;

          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .liFirst {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            span {
              font-size: 24px;
              color: #000000;
              letter-spacing: 2px;
            }

            p {
              margin-top: 5px;

              span {
                font-size: 13px;
                color: #6d6d6d;
                margin-right: 5px;
                letter-spacing: 0px;
              }
            }
          }

          .liSecond {
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px;

              span {
                font-size: 40px;
              }

              .classRed {
                color: #f4382c;
              }

              .classGreen {
                color: #0fb255;
              }

              img {
                width: 10px;
                height: auto;
                margin-left: 10px;
              }
            }

            p:nth-child(2) {
              margin-bottom: 10px;

              span {
                font-size: 14px;
                color: #6d6d6d;
                margin-right: 10px;
              }
            }
          }
        }

        ul:nth-child(1) {
          margin-right: 50px;
        }
      }
    }
  }

  .Float {
    width: 239px;
    height: 362px;
    background: url('../../static/index/float.png') no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 40%;
    right: 0%;
    z-index: 3;
    cursor: pointer;

    img {
      position: absolute;
      top: -4%;
      right: 1px;
      cursor: pointer;
    }
  }

  .dark {
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      /* 背景颜色设置为半透明黑色 */
    }

    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: 1;
      position: relative;
      left: -50%;
    }

    .closer3 {
      width: 30px;
      position: relative;
      top: -260px;
      right: 0%;
      z-index: 3;
      cursor: pointer;
    }
  }

  .lanternRight {
    position: fixed;
    top: 50%;
    right: 5%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .lanternLeft {
    position: fixed;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .fixedWebSocket {
    position: inherit;
  }

  .liveRoom {
    width: 400px;
    height: 250px;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 99;
  }
}

/deep/ #box {
  margin-top: 100px;
}

// 设置swiper分页器的样式
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  // bottom: 55px;
}

/deep/ .swiperOne {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 4px;
    height: 4px;
    background-color: #999999;
    border-radius: 50%;
  }

  li {
    width: 20px;
    height: 20px;
    border: 2px solid #d52226;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: 4px;
      height: 4px;
      background-color: #d52226;
      border-radius: 50%;
    }
  }

  .swiperOneSpan {
    margin: 0 30px;
  }
}

/deep/ .swiperTwo {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 4px;
    height: 4px;
    background-color: #999999;
    border-radius: 50%;
  }

  li {
    width: 20px;
    height: 20px;
    border: 2px solid #d52226;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;

    span {
      width: 4px;
      height: 4px;
      background-color: #d52226;
      border-radius: 50%;
    }
  }
}

/deep/ .swiperThree {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 4px;
    height: 4px;
    background-color: #999999;
    border-radius: 50%;
  }

  li {
    width: 20px;
    height: 20px;
    border: 2px solid #d52226;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: 4px;
      height: 4px;
      background-color: #d52226;
      border-radius: 50%;
    }
  }

  .swiperThreeSpan {
    margin: 0 30px;
  }
}

@media screen and (max-width: 500px) {

  .indexBox {
    .bigImg {
      height: 140px;
      background: url('../../static/index/wapImg/banner.jpg') no-repeat;
      background-size: 100% 100%;
    }

    .swiperBox {
      width: 100%;
      height: 140px;

      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            padding: 0;

            img {
              width: 100%;
              height: 140px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .information {
      height: auto;
      padding: 1px 0 30px;
      margin-top: 50px;
      background: none;
      background-color: #f5f4fa;
    }

    .webSocket {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      padding: 10px 0;

      .wssBox {
        width: 100%;
        flex-direction: column;

        div {
          width: 100%;

          ul:nth-child(1) {
            margin-right: 10px;
          }

          ul {
            width: 45%;
            height: 45px;

            .liFirst {
              span {
                font-size: 16px;
              }

              p {
                margin-top: 0;

                span {
                  font-size: 12px;
                }
              }
            }

            .liSecond {
              p {
                span {
                  font-size: 18px;
                }

                img {
                  width: 5px;
                  margin-top: 0;
                  margin-left: 5px;
                }
              }
            }
          }
        }

        img {
          width: 50%;
          margin-top: 10px;
        }
      }
    }

    .media {
      box-sizing: border-box;
      padding-top: 0px;
    }

    .dark {
      img {
        width: 343px;

      }
    }
  }

}

.floatBox {
  position: relative;
  left: -28px;

  ul {
    width: 275px;
    position: absolute;
    top: 50px;
    left: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 20px 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      img {
        width: 100px !important;
        height: auto !important;
        position: unset !important;
        display: block;
      }

      span {
        margin-top: 5px;
        color: #000;
        line-height: 29px;
      }
    }

    li:nth-child(1) {
      margin-right: 55px;
    }
  }
}

.codeBox {
  display: flex;
}
</style>
