<template>
  <div class="box" @mouseleave="leaveLive">
    <p><span>红狮课堂</span>直播中...</p>
    <img class="closer" @click="closerLiveRoom" src="../../../public/subject/worldCup/closer2.png" alt="">
    <video @mouseenter="hoverLive" :src="video"></video>
    <div @click="goClassRoom" v-if="control">
      打开直播间页面
      <img src="../../../static/common/tool.png" alt="">
    </div>
  </div>
</template>

<script>
import {livingRoomNow} from '../../../api/info'
export default {
  name:'liveRoom',
  data(){
    return{
      video:'',
      control:false,
    }
  },
  created(){
    this.init();
  },
  methods:{
    init(){
      livingRoomNow({platid:1}).then(res=>{
        if(res.Status == 0 && res.content.length>0){
          this.video = res.content[0].backimg;
          // 如果获取到值说明正在直播中，传给父元素让其展示正在直播弹窗；
          this.$emit('nowLiving',false);
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    hoverLive(){
      this.control = true;
    },
    leaveLive(){
      this.control = false;
    },
    goClassRoom(){
      this.$router.push({
        name:'classRoom',
      });
    },
    closerLiveRoom(){
      this.$emit('nowLiving',false);
    }
  },
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: 100%;
    background-color: #000000;
    box-sizing: border-box;
    padding: 5px 0 0 5px;
    p{
      color: #fff;
      span{
        color: #ff464a;
      }
    }
    .closer{
      position: absolute;
      top: 7px;
      right: 7px;
      width: 15px;
      cursor: pointer;
    }
    video{
      width: 95%;
      height: 200px;
      cursor: pointer;
    }
    div{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #fff;
      cursor: pointer;
      background-color: rgba(194, 192, 192, 0.46);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 3px 8px;
      height: 20px;
      img{
        margin-left: 5px;
      }
    }
  }
</style>